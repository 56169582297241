<template>
  <div id="modal__ptePractice" class="md-sl__slide">
      <div v-if="!getQuestion.id" style="height: 400px">
        <Loader/>
      </div>
      <form  @submit.prevent="onSubmit" class="p-2" v-else :style="{pointerEvents: timesUpDialog ? 'none' : 'inherit'}">
        <div v-for="(item,index) in getQuestion.quizzes" :key="index" v-show="current_slide_number === index" :class="{'is-active-slide': current_slide_number === index}" class="md-layout-column md-sl__br">
            <!-- slide question header start-->
            <div class="md-sl__h1">
              <md-toolbar class="md-sl__header" >
                  <Dialog path='student.quizTest.nav' @dailogHide="dailogHide" />
                  <div class="md-sl__slide-header__left">
                    <div>
                        <h2>{{getQuestion.title}}</h2>
                        <p>QUIZ ID {{ timesUpDialog }}<span style="color:#762cb5">{{getQuestion.q_s_id}}</span></p>
                    </div>
                  </div>
                  <div class="timer_text">
                    <md-button class="md-sl__timer">{{ countDown }}</md-button>
                  </div>
              </md-toolbar>
            </div>
            <!-- slide question header end-->
            <div class="body_section">
              <!-- quiz body start-->
              <div>
                  <components ref="perent" :is="item.quiz_type_slug" :quiz="item"></components>
                  <components ref="child" v-model="quizz[index]" :is="convertors(item.answer_type)" :quiz="item"></components>
              </div>
              <!-- quiz body end-->
              <!-- bottom section start-->
              <div class="md-layout mb-3">
              <div class="flex">
                  <div class="md-layout-item">
                    <md-button class="md-sl__font_600 md-sl__count">{{ index+1 }} of {{ getQuestion.quizzes.length }}</md-button>
                  </div>
              </div>
              <div class="md-layout-item" :class="getQuestion.quizzes.length  == selectedQuiz && layoutMeta !== 'teacher' ? '' : 'disable'">
                <md-button  class="md-sl__font_600 md-sl__white back-blue"  @click="onSubmit">SUBMIT</md-button>
              </div>
              <div class="md-layout-item md-sl__next_body">
                  <span class="md-sl__button_n_body">
                  <span>
                      <md-button class="md-sl__white" @click="prevSlide(index)" :class="current_slide_number!== 0 ? 'bg-victoria': 'bg-periwinkle-gray'">
                        <span class="md-sl__pre" >
                            <ArrowIcon/>
                        </span>
                          PREVIOUS
                      </md-button>
                  </span>
                  <span v-if="item.length-1!=current_slide_number">
                      <md-button class="md-sl__white" @click="nextSlide(index)" :class="getQuestion.quizzes.length-1 !== current_slide_number ? 'bg-victoria': 'bg-periwinkle-gray'">
                        NEXT
                        <span class="md-sl__next">
                          <ArrowIcon/>
                        </span>
                      </md-button>
                  </span>
                  </span>
              </div>
              </div>
            </div>
        </div>
    </form>
    <md-dialog-confirm
      :md-active.sync="timesUpDialog"
      class="md-pte__dialog_body md-fixed md-dialog-overlay md-overlay"
      md-title="Times up!"
      md-content="If you <strong>leave</strong> from the Test, your changes will be <strong>lost.</strong>"
      md-confirm-text="Submit Test"
      md-cancel-text="Cancel"
      @md-cancel="dailogHide(false)"
      @md-confirm="onSubmit"
      style="position: fixed !important"
    />
  </div>
</template>

<script>
import ArrowIcon from '@/assets/svg/arrowIcon.svg';
import Questions from "@/views/student/quiz-test/item";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loader from '@/components/atom/loader.vue';
import Dialog from '@/views/student/quiz-test/QuizDialog.vue';
  export default {
    name: 'DialogCustom',
    components:{
      ArrowIcon,
      ...Questions,
      Loader,
      Dialog
    },
    props: {
      countDown: {
        type: Number,
        required: true
      }
    },
    data() {
      return {
          current_slide_number: 0,
          form: {
            quiz_set_id: '',
            quizzes: []
          },
          quiz_id: '',
          quizz: {},
          selectedQuiz: 0,
          layoutMeta: '',
          timesUpDialog: false,
        }
    },
    computed: {
      ...mapGetters({
        getQuestion: "quiz/getQuestion",
        getQuizId: "quiz/getQuizId",
      })
    },
    watch: {
      quizz(value) {
        this.selectedQuiz = Object.keys(value).length
      },
      countDown(val) {
        if(val === 0) {
          this.timesUpDialog = true;
        }
      }
    },
    mounted() {
       this.layoutMeta = this.$route.meta.layout;    
    },
    methods:{
        ...mapActions({
          actAnswerSubmit: "quiz/actAnswerSubmit",
        }),
        ...mapMutations({
          dialog: "setShowDialog",
          setShowSnackbar: "setShowSnackbar"
        }),
        convertors(title){
          if(title === 'MCQ-CHOOSE-MULTIPLE-ANSWER'){
            return 'MCQChooseMultipleAnswer'; 
          }
          if(title === 'MCQ-CHOOSE-SINGLE-ANSWER'){
            return 'MCQChooseSingleAnswer'; 
          } 
          if( title === 'TEXT'){
            return 'TextAnswer'
          }
        },
        nextSlide(index){
          if(this.getQuestion.quizzes.length-1!=this.current_slide_number){
            this.current_slide_number++;
            console.log(index+1)
          } else {
            this.current_slide_number;
          }
        },
        prevSlide(index){
          if(this.current_slide_number!=0){
            this.current_slide_number--;
            console.log(index-1)
          } else {
            this.current_slide_number;
          }
        },
        dailogHide(data){
          this.$emit("dailogHide", data);
        },
        onSubmit(){
          if(this.timesUpDialog && !this.selectedQuiz) {
            this.dailogHide(false);
          } else {

            this.quiz_id = this.getQuizId.id;
            this.form.quiz_set_id = this.getQuizId.id;
            this.form.quizzes = this.quizz;
            console.log(this.quizz,'this.quiz_id',this.form)
            this.actAnswerSubmit({
              quiz_set_id: this.quiz_id,
              ...this.form
            }).then(({ message }) => {
             console.log(message);
              this.$router.push({
                name: 'short-result.evaluation',
                params: {
                  id: this.quiz_id
                }
              });
            });
          }
        },
    },
}
</script>
