<template>
  <md-dialog class="quiz_test_body" :md-active="showStudyGuideDialog">
    <component :is="component" :countDown="countDown" @dailogHide="dailogHide" :layout="layout"></component>
  </md-dialog>
</template>

<script>
import OneByOne from "@/views/student/quiz-test/quizType/OneByOne.vue";
import AllInOne from "@/views/student/quiz-test/quizType/AllInOne.vue";
import { mapMutations, mapGetters } from "vuex";
export default {
  components: {
    OneByOne,
    AllInOne,
  },
  props:{
    showStudyGuideDialog: Boolean,
    component: {
      default: true
    },
    layout: {
      type: String
    }
  },
  data() {
    return {
      showDialog: true,
      countDown: 0.00
    };
  },
  computed: {
      ...mapGetters({
        getQuestion: "quiz/getQuestion",
      })
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    dailogHide(){
      this.showStudyGuideDialog = false;
      this.dialog(false);
    },
    countDownTimer () {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      }
    }
  },
  watch: {
    getQuestion(val) {
      if(val && val.duration) {
        this.countDown = val.duration; 
        this.countDownTimer();
      }
    }
  },
  created () {
      this.countDown = this.getQuestion && this.getQuestion.duration ? this.getQuestion.duration : 0; 
      this.countDownTimer();
  }
};
</script>

<style></style>
