<template>
  <div id="quiz_test" class="md-sl__slide">
      <div v-if="!getQuestion.id" style="height: 400px">
        <Loader/>
      </div>
      <form @submit.prevent="Handlesubmit" class="p-2" :style="{pointerEvents: timesUpDialog ? 'none' : 'inherit'}">
        <div class="md-layout-column md-sl__br">
            <!-- slide question header start-->
            <div class="md-sl__h1">
              <md-toolbar class="md-sl__header" >
                  <Dialog path='student.quizTest.nav' @dailogHide="dailogHide"/>
                  <div class="md-sl__slide-header__left">
                    <div>
                        <h2>{{getQuestion.title}}</h2>
                        <p>QUIZ ID <span style="color:#762cb5">{{getQuestion.q_s_id}}</span></p>
                    </div>
                  </div>
                  <div class="timer_text">
                    <md-button class="md-sl__timer">{{ countDown }}</md-button>
                  </div>
              </md-toolbar>
            </div>
            <div class="body_section">
              <!-- slide question header end-->
              <!-- quiz body start-->
              <div v-for="(item,index) in getQuestion.quizzes" :key="index">
                  <div class="flex justify-between ml-2">
                      <div>
                        <md-button class="rounded-full px-2 back-blue text-white">Quiz Question {{index+1}}</md-button>
                      </div>
                  </div>
                  <components ref="perent" :is="item.quiz_type_slug" :quiz="item"></components>
                  <components  ref="child" v-model="quizz[index]" :is="convertors(item.answer_type)" :quiz="item"></components>
              </div>
              <!-- quiz body end-->
              <!-- bottom section start-->
              <div class="flex justify-center mb-3" :class="getQuestion.quizzes.length  === selectedQuiz && layoutMeta !== 'teacher' ? '' : 'disable'">
                  <div class="md-layout-item">
                      <md-button  class="md-sl__font_600 md-sl__white back-blue" @click="onSubmit">SUBMIT</md-button>
                  </div>
              </div>
            </div>
        </div>
    </form>
    <md-dialog-confirm
      :md-active.sync="timesUpDialog"
      class="md-pte__dialog_body"
      md-title="Times up!"
      md-content="If you <strong>leave</strong> from the Test, your changes will be <strong>lost.</strong>"
      md-confirm-text="Submit Test"
      md-cancel-text="Cancel"
      @md-cancel="dailogHide(false)"
      @md-confirm="onSubmit"
    />
  </div>
</template>

<script>
import ArrowIcon from '@/assets/svg/arrowIcon.svg';
import Questions from "@/views/student/quiz-test/item";
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Loader from '@/components/atom/loader.vue';
import Dialog from '@/views/student/quiz-test/QuizDialog.vue';
  export default {
    name: 'DialogCustom',
    props: {
      countDown: {
        type: Number,
        required: true
      }
    },
    components:{
      ArrowIcon,
      ...Questions,
      Loader,
      Dialog
    },
    data() {
      return {
          form: {
            quiz_set_id: '',
            quizzes: []
          },
          quiz_id: '',
          quizz: {},
          selectedQuiz: 0,
          layoutMeta: '',
          timesUpDialog: false,
      }
    },
  computed: {
    ...mapGetters({
      getQuestion: "quiz/getQuestion",
      getQuizId: "quiz/getQuizId",
    })
  },
  watch: {
    quizz(value) {
      this.selectedQuiz = Object.keys(value).length
    },
    countDown(val) {
      if(val === 0) {
        this.timesUpDialog = true;
      }
    },
  },
  mounted() {
    this.layoutMeta = this.$route.meta.layout;    
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar"
    }),
    ...mapActions({
      actAnswerSubmit: "quiz/actAnswerSubmit",
    }),
    convertors(title){
      if(title === 'MCQ-CHOOSE-MULTIPLE-ANSWER'){
        return 'MCQChooseMultipleAnswer'; 
      }
      if(title === 'MCQ-CHOOSE-SINGLE-ANSWER'){
        return 'MCQChooseSingleAnswer'; 
      } 
      if( title === 'TEXT'){
        return 'TextAnswer'
      }
    }, 
    dailogHide(data){
      this.$emit("dailogHide", data);
    },
    onSubmit(){
      if(this.timesUpDialog && !this.selectedQuiz) {
        this.dailogHide(false);
      } else {

        this.quiz_id = this.getQuizId.id;
        this.form.quiz_set_id = this.getQuizId.id;
        this.form.quizzes = this.quizz;
        this.actAnswerSubmit({
          quiz_set_id: this.quiz_id,
          ...this.form
        }).then(({ message }) => {
          console.log(message);
          this.$router.push({
            name: 'short-result.evaluation',
            params: {
              id: this.quiz_id
            }
          });
        });
      }
    },
  },
  
};
</script>
