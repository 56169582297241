<template>
    <div class="px-6 pt-12">
        <h2 class="mb-4 text-2xl">Instruction</h2>
        <span>
            <p class="text-base">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        </span>
        <div class="mt-12 mb-6 flex justify-center">
            <Button
                class="rounded text-white bg-primary px-4 mr-0 border border-solid border-gray-500 mr-4"
                label="Start Quiz"
                type="submit"
                @click="StartQuiz()"
                >
            </Button>
        </div>
        <QuestionView :component="this.component" :showStudyGuideDialog.sync="showMediaDialog"/>
    </div>
</template>
<script>
import {
  Button,
} from "@/components";
import QuestionView from "@/views/student/quiz-test/QuestionView.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  components: {
    Button,
    QuestionView
  },
  props:{
    Quiz_item: {
      type: [Object,Array],
      default: () => null
    },
  },
  data() {
    return {
      component: null,
      templete: '',
      showMediaDialog: false,
      quiz_id: ''
    };
  },
  computed: {
    ...mapGetters({
      getQuestion: "quiz/getQuestion",
    })
  },
  methods:{
    ...mapActions({
      actShowQuizs: "quiz/actShowQuizs",
      actQuizStart: "quiz/actQuizStart"
    }),
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    StartQuiz() {
      console.log(this.Quiz_item)
      //this.dialog(false);\
      if(this.Quiz_item){
        localStorage.setItem("templete_tyoe", this.Quiz_item.template_type);
        localStorage.setItem("quiz_id",this.Quiz_item.id);
      }
       
      this.templete = this.Quiz_item ? this.Quiz_item.template_type : localStorage.getItem("templete_tyoe");
      this.quiz_id = this.Quiz_item ? this.Quiz_item.id : localStorage.getItem("templete_tyoe");
       if(this.templete === "ONE_BY_ONE") {
          this.component = 'OneByOne';
          //this.component = 'AllInOne'
          this.showMediaDialog = true;
          // this.$router.push({
          //   name: 'student.quizTestPreview',
          //   params: {
          //     id: this.quiz_id
          //   }
          // });
      } else {
          this.component = 'AllInOne';
          // this.$router.push({
          //   name: 'student.quizTestPreview',
          //   params: {
          //     id: this.quiz_id
          //   }
          // });
          this.showMediaDialog = true;
      }
      this.actShowQuizs(this.quiz_id); 
      let data = {
        quiz_set_id: this.quiz_id
      }
      this.actQuizStart(data);
    },
  }
}
</script>